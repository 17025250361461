.SlideItem {
	padding: 25px 0;
	width: 25%;
	float: left;
	border: 1px solid #e3e6e8;
	position: relative;
	text-align: center;
}

.SlideItem-LinenCare {
	min-height: 390px;
}

.SlideItem-HomeComfort {
	min-height: 536px;
}

.SlideItem:hover {
	border-color: #20a9ca;
}

.SlideItem_Img {
	height: auto;
	padding: 10px 0;
}

.SlideItem_Caption {
	width: 100%;
	padding: 0 25px 20px 25px;
}

.SlideItem_Title {
	font-size: 21px;
	margin: 0 0 4px;
	padding: 0 0 10px;
	color: #009DC0;
	line-height: 1em;
	text-transform: uppercase;
	position: relative;
	min-height: 52px;
	width: 80%;
  margin: 0 auto;
}

.SlideItem_Title:after {
	content: "";
	width: 25px;
	position: absolute;
	bottom: 0;
	display: block;
	left: 50%;
	margin-left: -12px;
	background: #A4B0B8;
	height: 1px;
}

.SliderItem_Description {
	font-size: 16px;
	color: #6d6e70;
	margin: 15px auto;
	min-height: 0;
	line-height: 1.2em;
	letter-spacing: -0.009em;
	width: 80%;
}

@media screen and ( max-width: 460px) {
	.SlideItem {
		width: 100%;
	}
}

@media screen and ( min-width: 460px) and ( max-width: 768px) {
	.SlideItem {
		width: 50%;
	}
}