.GuaranteeBlock{
    padding: 18px 20px;
    background: #EDEEF0;
    margin: 0 0 15px;
}

.GuaranteeBlock .item h2{
    font-weight: 100;
    margin: 0 0 12px;
    padding: 12px 0;
    border-bottom: 1px solid #DCDEE2;
    border-top: 1px solid #DCDEE2;
    color: #009DC0;    
    line-height: 24px;    
    font-size: 24px;
}

.GuaranteeBlock .item p{
    font-size: 14.5px;
}

.GuaranteeBlock .item ul{
    padding: 0;
    margin: 0 0 10px 25px;
}
.GuaranteeBlock .item ul li{
    margin-bottom: 5px;
    text-align: left;
}
.GuaranteeBlock a{
    text-decoration: underline;
    font-size: 14px;
}
.GuaranteeBlock .GuaranteeBottom, .GuaranteeBlock .GuaranteeBottom a{
    font-size: 12px;
}

@media only screen and (max-width: 600px) {

}

