.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search-field {
  font-size: 16px;
  width: 0;
  opacity: 0;
  transition: all 0.5s;
  background: #009dc0;
  color: #fff;
  border: 0;
}

.search-field::placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT sans";
}

.search-field:focus {
  box-shadow: unset;
  outline-color: unset;
  outline: 1px solid #019cc0;
}

.search-field.expand {
  padding: 15px 35px 15px 15px;
  margin-right: 15px;
  width: 250px;
  opacity: 1;
}

.search-submit {
  background: none;
  border: 0;
  cursor: pointer;
}

.search {
  display: block;
}

.search-results-container {
  display: block;
  position: relative;
  width: 100%;
}

.search-results {
  margin-top: 0;
  background: #056f88;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.result {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  border-bottom: 1px solid #0185a3;
  text-align: center;
}

.result:hover {
  background: #0185a3;
}

.result a {
  padding: 10px 0;
  display: block;
  color: #fff;
}

@media (max-width: 400px) {
  .search-field.expand {
    width: 175px;
  }
}