.Footer {
	margin-top: 34px;
}
.Footer:after {
	content: '';
    position: relative;
    z-index: -1;
    display: block;
    width: 100%;
    height: 9px;
    background: #B1BDC5;
}
.FooterLogo {
	float: left;
	width: 216px;
    margin-top: -6px;
    padding: 0 16px;
    background-color: #fff;
}
.FooterLogo img {
	max-width: 95%;
    vertical-align: middle;
}
.FooterLinks {
	float: right;
}
.FooterLinks li{
	display: inline-block;

}
.FooterLinks li a{
	padding: 0 12px;
	color: #6d6e70;
    text-transform: uppercase;
    font-size: 12px;

}