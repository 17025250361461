.banner {
  background: #1F272F;
}

.banner-content {
  display: flex;
  justify-content: space-between;
}

.banner-desc {
  padding: 25px 0;
  margin-top: 15px;
}

.banner-title {
  font-family: "PT italics", sans-serif;
  color: #fff;
  font-size: 40px;
  font-weight: lighter;
  margin: 0 0 15px 0;
}

.banner-excerpt {
  color: #B0BCC4;
  margin: 0 0 5px;
  line-height: 1.2em;
  font-size: 16px;
  width: 500px;
}

.banner-cta {
  background-size: cover;
  width: 500px;
  height: 350px;
  text-align: right;
  padding-right: 50px;
}

.banner-featured-title {
  text-transform: uppercase;
  color: #fff;
  font-family: 'PT sans';
  margin: 0;
  font-size: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  width: 225px;
  margin-left: auto;
  margin-top: 65px;
}

.banner-featured-desc {
  color: #fff;
  font-family: "PT italics", sans-serif;
  font-size: 22px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .banner-content {
    flex-direction: column;
  }
  .banner-cta, .banner-excerpt, .banner-title {
    width: 100%;
    text-align: center;
  }
  .banner-desc, .banner-cta {
    padding: 25px;
  }
  .banner-featured-title {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}