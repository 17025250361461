.AboutUsBanner {
	background: url(../images/about-us-bg.jpg);
	text-align: center;
    padding: 44px 0;
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
}
.AboutUsBanner h1 {
    line-height: 1.5em;
    font-size: 45px;
    font-size: 3.0rem;
    color: #6d6e70;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Uni Sans', sans-serif;
    font-weight: bold;
}
.AboutRowenta {
	padding: 35px 60px;
    background: #F6F7F7;
    margin-bottom: 50px;
}
.AboutRowenta h2 {
	line-height: 1.5em;
    text-align: center;
    font-family: 'PT italics';
    font-weight: 100;
    color: #A4B0B8;
    margin: 0 0 55px;
    font-size: 35px;
    font-size: 2.2rem;
    position: relative;
    display: block;
}
.AboutRowenta h2:after {
	width: 60px;
    height: 1px;
    background: #A4B0B8;
    content: "";
    position: absolute;
    bottom: -22px;
    display: block;
    left: 50%;
    margin-left: -30px;
}
.AboutRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
    font-size: 21px;
    font-size: 1.31rem;
    margin: 0 0 10px;
    font-weight: 100;
    line-height: 1.2em;
}
.AboutRowentaContent {
	margin-top: 40px;
}
.AboutRowentaContent h3 {
	font-family: 'PT italics';
	font-size: 26px;
	font-size: 1.6rem;
    color: #009DC0;
    font-weight: 100;
    padding: 20px 23px;
    margin: 0;
}
.AboutRowentaContent img {
	width: 500px;
}
.AboutRowentaContent a {
	color: #6d6e70;
	font-size: 18px;
}
.AboutRowentaInnovators,
.AboutRowentaSebgroup {
	width: 500px;
	margin-bottom: 35px;
    background: #EDEEF0;
    display: inline-block;
    padding-bottom: 40px;
}
.AboutRowentaInnovators {
	margin-right: 80px;
}
.AboutRowentaConentTxt {
	padding: 0 30px;
}
.AboutRowentaContentLead {
	font-size: 1.2rem;
    font-weight: 100;
    margin: 0 0 10px;
    line-height: 1.2em;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
	.AboutRowenta {
		margin: 0 15px;
		padding: 15px;
	}
	.AboutRowenta h2 {
	    margin: 0 0 45px;
	}
	.AboutRowentaIntroLead {
	    font-size: 19px;
	    font-size: 1.2rem;
	}
	.AboutRowentaInnovators {
		margin: 0 auto 30px;
	}
	.AboutRowentaInnovators,
	.AboutRowentaSebgroup {
		width: 100%;
	}
	.AboutRowentaContent h3 {
		font-size: 1.3rem;
		padding: 20px 15px;
	}
	.AboutRowentaConentTxt {
	    padding: 0 15px;
	}
}

