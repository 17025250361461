.Headerpage {
	border-bottom: 1px solid #dbdcdc;
	min-height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 50;
}

.ToggleNav {
	display: flex;
	justify-content: center;
	position: relative;
	padding: 12px 23px 38px 23px;
	transition: .25s;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	float: left;
	font-size: 12px;
	color: #fff;
	background-color: #009DC0;
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
}

.ToggleNav .burger-icon:before, .ToggleNav .burger-icon:after {
	transition: .25s;
}

.ToggleNav:hover .burger-icon:before {
	top: 9px;
}

.ToggleNav:hover .burger-icon:after {
	top: -9px;
}

.ToggleNav.active:hover .burger-icon {
	-webkit-transform: scale(.8, .8);
	transform: scale(.8, .8);
}

.ToggleNav.active .burger-icon {
	background: transparent;
	transition: all 0.3s;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.active:not(.steps) .burger-icon:before, .active:not(.steps) .burger-icon:after {
	top: 0 !important;
}

.ToggleNav:hover .burger-icon:before {
	top: 9px;
}

.ToggleNav.active .burger-icon:before {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.ToggleNav:hover .burger-icon:after {
	top: -9px;
}

.ToggleNav.active .burger-icon:after {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.active .burger-icon:before, .active .burger-icon:after {
	transition: .5s;
}

.Logo {
	max-width: 80%;
}

.HeaderCenter {
	text-align: center;
}

.HeaderRight {
	padding: 7px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: row;
	flex-wrap: wrap;
	padding-right: 0 !important;
}

.repair-logo {
	width: 60px;
  padding-right: 10px;
  border-right: 1px solid #e4e4e4;
  margin-right: 10px;
}

.back-button {
	position: absolute;
	left: -60px;
	top: 10px;
	display: none;
}

@media screen and (min-width: 440px) {
	.Headerpage {
		min-height: 60px;
	}
	.ToggleNav {
		font-size: 12px;
	}
	.HeaderRight {
		padding: 14px 20px;
	}
	.Logo {
		max-width: 100%;
	}
}
body.no-scroll{
    overflow: hidden;
}

.main-nav {
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    padding-top: 80px;
    left: 0;
    text-align: left;
    z-index: 40;
    transition: all 0.4s;
    -webkit-transform: translateX(-320px);
    transform: translateX(-320px);
}

.main-nav a, .main-nav h2{
    color: #fff;
    font-family: 'PT sans';
}

.main-nav a{
    text-transform: uppercase;
}

.main-nav h2, .main-nav .h2 {
    margin: 0;
    padding: 13px 0 11px;
    line-height: 1.3em;
    color: #fff;
    font-weight: normal;
    font-family: "PT", sans-serif;
    text-align: center;
    font-size: 24px;
}

.main-nav h2 strong, .main-nav .h2 strong {
    font-family: "uni",sans-serif,sans-serif;
    text-transform: uppercase;
    display: block;
    font-size: 32px;
}

.main-nav.show-nav {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.main-nav .niv1 {
    position: relative;
    background: #00ADD0;
    width: 320px;
    height: 100%;
}

.main-nav li img {
    max-width: 43px;
    position: relative;
    margin: 0 8px 0 19px;
    float: left;
    z-index: 10;
}

.main-nav li.secondary-nav img {
    margin: 0 8px 0 0;
}

.main-nav .navigation a, .main-nav .navigation span {
    outline: none;
    display: block;
    text-decoration: none;
    padding: 12px 20px 12px;
    text-transform: uppercase;
    color: #fff;
    height: 100%;
    position: relative;
    font-size: 15px;
    transition: all 0.3s;
}

.main-nav .navigation a:before, .main-nav .navigation span:before {
    display: block;
    position: absolute;
    font-size: 30px;
    right: 15px;
    top: 38%;
    margin: -15px 0 0 0;
    content: "\203A";
    font-family: ico;
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #fff;
    z-index: 10;
}

.main-nav .navigation a:after, .main-nav .navigation span:after {
    height: 1px;
    background: #0093BB;
    left: 20px;
    right: 20px;
    position: absolute;
    bottom: 0;
    content: "";
    display: block;
    transition: all 0.3s;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.main-nav li.secondary-nav a, .main-nav li.secondary-nav span {
    position: relative;
}

.main-nav li.secondary-nav a:after, .main-nav li.secondary-nav span:after {
    left: 0;
    right: 0;
    background: #00ADD0;
}

.main-nav .navigation li a:after, .main-nav .navigation li span:after {
    height: 1px;
    background: #0093BB;
    left: 20px;
    right: 20px;
    position: absolute;
    bottom: 0;
    content: "";
    display: block;
    transition: all 0.3s;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.main-nav .navigation li.direct-link a:before, .main-nav .navigation li.direct-link span:before {
    display: none;
}

.main-nav li {
    list-style-type: none;
    margin-top: -1px;
    clear: both;
}

.main-nav li.secondary-nav ~ li.secondary-nav {
    margin-top: 0;
}

.main-nav li.secondary-nav {
    background: #009DC0;
    margin: 20px 20px 0 20px;
}

.main-nav .navigation .niv2 {
    position: absolute;
    z-index: -1;
    top: 0;
    min-width: 320px;
    left: 320px;
    height: 100%;
    background: #33BDD9;
    color: #6d6e70;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: all 0.4s;
    -webkit-transform: translateX(-320px);
    transform: translateX(-320px);
    visibility: hidden;
}

.main-nav .navigation .niv2.open {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    right: 320px;
    visibility: visible;
}

.main-nav .navigation .niv2 .col {
    float: left;
    width: 320px;
    padding: 20px;
}

.main-nav .navigation .niv2 h2, .main-nav .navigation .niv2 .h2 {
    border-top: 1px solid #64CDEB;
    border-bottom: 1px solid #64CDEB;
    padding: 7px 0 9px;
    margin: 0 0 17px;
}

.main-nav .navigation .niv2 .col li {
    height: 44px;
    display: table;
    width: 100%;
}

.main-nav .navigation .niv2 .col li a {
    display: table-cell;
    vertical-align: middle;
    padding: 3px 16px 0 9px;
    line-height: 1.2em;
    height: 100%;
}

.main-nav .navigation .niv2 li a {
    color: #fff;
    min-height: 49px;
    border-bottom: 1px solid #99DEEC;
}

.main-nav .navigation .niv2 li a:before {
    display: none;
}

.main-nav .navigation .niv2 li a:after {
    display: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    background-color: #DBDFE4;
}

.main-nav .navigation a.all, .main-nav .navigation span.all {
    background: #009DC0;
}

.main-nav .navigation .niv2 li a:hover {
    background-color: #fff;
    color: #009DC0;
}

.main-nav .navigation a:hover, .main-nav .navigation span:hover {
    background: #33BDD9;
    color: #fff;
}

.overlay {
    position: fixed;
    z-index: 39;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transition: all 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    visibility: hidden;
}

.overlay.open {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: .7;
    visibility: visible;
    cursor: pointer;
}
.burger-icon {
    position: absolute;
    bottom: 20px;
    width: 30px;
    height: 4px;
    transition: .5s;
    background: #fff;
    float: left;
    z-index: 1;
    margin: 0;
}

.burger-icon:before {
    top: 7px;
}

.burger-icon:after {
    top: -7px;
}

.burger-icon:before, .burger-icon:after {
    display: block;
    content: "";
    width: 30px;
    height: 4px;
    position: absolute;
    z-index: -1;
    transition: .5s .25s;
    background: #fff;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search-field {
  font-size: 16px;
  width: 0;
  opacity: 0;
  transition: all 0.5s;
  background: #009dc0;
  color: #fff;
  border: 0;
}

.search-field::-webkit-input-placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT sans";
}

.search-field::-ms-input-placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT sans";
}

.search-field::placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT sans";
}

.search-field:focus {
  box-shadow: unset;
  outline-color: unset;
  outline: 1px solid #019cc0;
}

.search-field.expand {
  padding: 15px 35px 15px 15px;
  margin-right: 15px;
  width: 250px;
  opacity: 1;
}

.search-submit {
  background: none;
  border: 0;
  cursor: pointer;
}

.search {
  display: block;
}

.search-results-container {
  display: block;
  position: relative;
  width: 100%;
}

.search-results {
  margin-top: 0;
  background: #056f88;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.result {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  border-bottom: 1px solid #0185a3;
  text-align: center;
}

.result:hover {
  background: #0185a3;
}

.result a {
  padding: 10px 0;
  display: block;
  color: #fff;
}

@media (max-width: 400px) {
  .search-field.expand {
    width: 175px;
  }
}
.ConsumerServices {
	clear: both;
	background: #1E252B;
	font-family: 'PT Sans', sans-serif;
	padding-bottom: 50px;
	padding-top: 30px;
}

.Services {
	display: flex;
	justify-content: space-between;
}

.ServiceTitle {
	font-size: 16px;
	font-weight: 100;
}

.Service {
	text-align: center;
	color: #fff;
	max-width: 175px;
	margin: 25px auto;
}

.ServiceDescription {
	color: #B1BDC5;
	font-size: 12px;
}

.ServiceHr {
	margin: 10px 50px;
  border-color: #868686;
}

@media (max-width: 768px) {
	.ConsumerServices{
		padding-bottom: 0;
	}

	.ConsumerServices .section-title{
		margin-bottom: 0;
	}

	.Services {
		flex-direction: column;		
	}

	.Services .Service{
		margin: 15px auto;
	}
}
.section-title {
  line-height: 1.5em;
  position: relative;
  font-weight: 100;
  color: gray;
  margin: 0 0 25px;
  font-size: 35px;
  display: block;
  width: auto;
  text-align: center;
  clear: both;
}

.section-title .top-title {
  font-size: 40px;
  padding: 5px 10px;
  position: relative;
}

.section-title .top-title:before {
  position: absolute;
  content: "";
  height: 1px;
  background: #bfbfbf;
  top: 50%;
  left: -20px;
  width: 25px;
}

.section-title .top-title:after {
  position: absolute;
  content: "";
  height: 1px;
  background: #bfbfbf;
  top: 50%;
  right: -20px;
  width: 25px;
}

.section-title .bottom-title {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
	width: 100%;
	font-family: 'PT sans';
}
.Footer {
	margin-top: 34px;
}
.Footer:after {
	content: '';
    position: relative;
    z-index: -1;
    display: block;
    width: 100%;
    height: 9px;
    background: #B1BDC5;
}
.FooterLogo {
	float: left;
	width: 216px;
    margin-top: -6px;
    padding: 0 16px;
    background-color: #fff;
}
.FooterLogo img {
	max-width: 95%;
    vertical-align: middle;
}
.FooterLinks {
	float: right;
}
.FooterLinks li{
	display: inline-block;

}
.FooterLinks li a{
	padding: 0 12px;
	color: #6d6e70;
    text-transform: uppercase;
    font-size: 12px;

}
.TopBanner {
	background-size: cover;
	background-position: center;
	color: #000;
	display: flex;
  justify-content: center;
	align-items: center;
	padding: 25px 0;
}

.TopBanner h1 {
	font-size: 45px;
	position: relative;
	font-family: 'PT sans', sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.1em;
	text-align: center;
	margin-bottom: 15px;
	margin-top: 0;
}

.TopBannerContent {
	text-align: center;
}

.TopBannerDesc {
	font-family: 'PT italics', sans-serif;
  font-size: 26px;
  text-align: center;
  width: 250px;
	margin: 0 auto;
	margin-bottom: 25px;
}

@media (min-width: 768px) {
	.TopBanner {
		min-height: 450px;
	}
}
.Nav {
	position: relative;
	z-index: 20;
	background-color: rgba(0, 157, 192, 0.8);
	color: #fff;
}

.active {
	background: #0185a3;
}

.Nav nav li a.active:hover {
	color: #fff;
}

.main-nav .navigation .niv2 h2, .main-nav .navigation .niv2 .h2 {
	font-family: "PT italics", sans-serif;
	font-weight: 100;
}

.Nav ul {
	text-align: left;
	width: 100%;
	height: 100%;
	min-height: 70px;
	padding: 0;
	margin: 0;
	font-size: 0;
	background-color: #00A7CA;
	background-color: rgba(0, 167, 202, 0.8);
}

.Nav nav li {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}

.Nav nav li:hover {
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.9);
}

.Nav nav li a {
	display: block;
	height: 100%;
	padding: 18px;
	line-height: 18px;
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
	font-family: 'Uni Sans';
	min-width: 130px;
	text-align: center;
	font-weight: 100;
}

.Nav nav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

.Nav nav li a:active {
	background: #1AB5D5;
	border-right: 1px solid #4AC5DD;
	border-left: 1px solid #4AC5DD;
}

.Nav nav li:nth-child(5) {
	float: right;
	background: #039FBF;
	border-left: 1px solid #4AC5DD;
}

.Nav nav li:nth-child(5):hover {
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.9);
}

.Nav.nav-subcat nav li:nth-child(5) {
	float: none;
	background: none;
	border-left: 0;
}

.Nav.nav-subcat nav li:nth-child(5):hover {
	background-color: none;
	background-color: none;
}

@media (max-width: 768px) {
	.Nav {
		display: none;
	}
}

@media screen and (max-width: 1023px) {
	.main-nav .navigation .niv2.open {
		left: 40px;
		box-shadow: 0 0 40px #444;
		z-index: 20;
	}
}
.FeaturedProducts {
	padding-top: 35px;
	padding-bottom: 50px;
}

.Products {
	margin: 25px 0;
	display: block;
	overflow: hidden;
}

@media (max-width: 768px) {
	.products {
		flex-direction: column;
	}
}
.SlideItem {
	padding: 25px 0;
	width: 25%;
	float: left;
	border: 1px solid #e3e6e8;
	position: relative;
	text-align: center;
}

.SlideItem-LinenCare {
	min-height: 390px;
}

.SlideItem-HomeComfort {
	min-height: 536px;
}

.SlideItem:hover {
	border-color: #20a9ca;
}

.SlideItem_Img {
	height: auto;
	padding: 10px 0;
}

.SlideItem_Caption {
	width: 100%;
	padding: 0 25px 20px 25px;
}

.SlideItem_Title {
	font-size: 21px;
	margin: 0 0 4px;
	padding: 0 0 10px;
	color: #009DC0;
	line-height: 1em;
	text-transform: uppercase;
	position: relative;
	min-height: 52px;
	width: 80%;
  margin: 0 auto;
}

.SlideItem_Title:after {
	content: "";
	width: 25px;
	position: absolute;
	bottom: 0;
	display: block;
	left: 50%;
	margin-left: -12px;
	background: #A4B0B8;
	height: 1px;
}

.SliderItem_Description {
	font-size: 16px;
	color: #6d6e70;
	margin: 15px auto;
	min-height: 0;
	line-height: 1.2em;
	letter-spacing: -0.009em;
	width: 80%;
}

@media screen and ( max-width: 460px) {
	.SlideItem {
		width: 100%;
	}
}

@media screen and ( min-width: 460px) and ( max-width: 768px) {
	.SlideItem {
		width: 50%;
	}
}
.banner {
  background: #1F272F;
}

.banner-content {
  display: flex;
  justify-content: space-between;
}

.banner-desc {
  padding: 25px 0;
  margin-top: 15px;
}

.banner-title {
  font-family: "PT italics", sans-serif;
  color: #fff;
  font-size: 40px;
  font-weight: lighter;
  margin: 0 0 15px 0;
}

.banner-excerpt {
  color: #B0BCC4;
  margin: 0 0 5px;
  line-height: 1.2em;
  font-size: 16px;
  width: 500px;
}

.banner-cta {
  background-size: cover;
  width: 500px;
  height: 350px;
  text-align: right;
  padding-right: 50px;
}

.banner-featured-title {
  text-transform: uppercase;
  color: #fff;
  font-family: 'PT sans';
  margin: 0;
  font-size: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  width: 225px;
  margin-left: auto;
  margin-top: 65px;
}

.banner-featured-desc {
  color: #fff;
  font-family: "PT italics", sans-serif;
  font-size: 22px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .banner-content {
    flex-direction: column;
  }
  .banner-cta, .banner-excerpt, .banner-title {
    width: 100%;
    text-align: center;
  }
  .banner-desc, .banner-cta {
    padding: 25px;
  }
  .banner-featured-title {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
.products {
  display: flex;
  justify-content: flex-start;
  margin: 15px 0;
}
.Hero {
	clear: both;
	max-height: 500px;
	overflow: hidden;
}

.HeroInner {
	background: linear-gradient(
		rgba(0, 0, 0, 0.25),
		rgba(0, 0, 0, 0.25)
	), url(/static/media/femme.80129ac8.jpg) no-repeat;
	background-size: cover;
	height: 200px;
	position: relative;
	width: 100%;
	margin: 0 auto;
	pointer-events: none;
	-webkit-animation: videoout 2.5s linear forwards;
	        animation: videoout 2.5s linear forwards;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	background: black;
	z-index: -1;
}

@-webkit-keyframes videoout {
  from {
		background: linear-gradient(
			rgba(0, 0, 0, 0.25),
			rgba(0, 0, 0, 0.25)
		), url(/static/media/femme.80129ac8.jpg) no-repeat;
	}
  to {
		background: rgba(0, 0, 0, 0.25);
	}
}

@keyframes videoout {
  from {
		background: linear-gradient(
			rgba(0, 0, 0, 0.25),
			rgba(0, 0, 0, 0.25)
		), url(/static/media/femme.80129ac8.jpg) no-repeat;
	}
  to {
		background: rgba(0, 0, 0, 0.25);
	}
}

.video-wrapper iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.8;
}

.Hero .h1 {
  position: absolute;
  top: 50%;
  z-index: 1;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px #44bdd8;
  text-align: center;
  line-height: 1em;
  font-weight: 300;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 auto;
  width: 100%;
}

.Hero h2 {
	position: absolute;
	text-shadow: 1px 1px 3px #44bdd8;
  bottom: 25px;
	margin: auto;
	z-index: 1;
	display: none;
	font-weight: 300;
	font-family: 'Merriweather', serif;
	text-align: center;
	font-size: 30px;
	margin: 0 auto;
  width: 100%;
}

.videoWrapper {
	display: NONE;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 460px) {
	.Hero .h1 {
		font-size: 30px;
	}
}

@media screen and (min-width: 768px) {
	.HeroInner {
		height: 300px;
	}
}

@media screen and ( min-width: 992px) {
	.HeroInner {
		height: 500px;
	}
	.Hero .h1 {
		font-size: 60px;
	}
	.Hero h2 {
		display: block;
		color: #fff;
	}
}
.SubNav {
	margin-top: 0;
	position: relative;
	z-index: 20;
	background-color: rgba(0, 157, 192, 0.8);
	color: #fff;
}

.SubNav ul {
	display: block;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	font-size: 0;
	background-color: #00A7CA;
	background-color: rgba(0, 167, 202, 0.8);
}

.SubNav nav li {
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	height: 50px;
}

.SubNav nav li:hover {
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.9);
}

.SubNav nav li a {
	display: block;
	height: 100%;
	padding: 0 18px;
	line-height: 50px;
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
}

.SubNav nav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

@media screen and (min-width: 440px) {
	.Subnav {
		margin-top: -70px;
	}
}

@media screen and ( min-width: 768px) {
	.SubNav ul {
		text-align: left;
		min-height: 70px;
		display: flex;
		justify-content: space-between;
	}
	.SubNav nav li {
		border-bottom: none;
		height: 70px;
		margin: 0% 3%;
	}
	.SubNav nav li a {
		line-height: 70px;
	}
}

.AboutUsBanner {
	background: url(/static/media/about-us-bg.9edacdbf.jpg);
	text-align: center;
    padding: 44px 0;
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
}
.AboutUsBanner h1 {
    line-height: 1.5em;
    font-size: 45px;
    font-size: 3.0rem;
    color: #6d6e70;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Uni Sans', sans-serif;
    font-weight: bold;
}
.AboutRowenta {
	padding: 35px 60px;
    background: #F6F7F7;
    margin-bottom: 50px;
}
.AboutRowenta h2 {
	line-height: 1.5em;
    text-align: center;
    font-family: 'PT italics';
    font-weight: 100;
    color: #A4B0B8;
    margin: 0 0 55px;
    font-size: 35px;
    font-size: 2.2rem;
    position: relative;
    display: block;
}
.AboutRowenta h2:after {
	width: 60px;
    height: 1px;
    background: #A4B0B8;
    content: "";
    position: absolute;
    bottom: -22px;
    display: block;
    left: 50%;
    margin-left: -30px;
}
.AboutRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
    font-size: 21px;
    font-size: 1.31rem;
    margin: 0 0 10px;
    font-weight: 100;
    line-height: 1.2em;
}
.AboutRowentaContent {
	margin-top: 40px;
}
.AboutRowentaContent h3 {
	font-family: 'PT italics';
	font-size: 26px;
	font-size: 1.6rem;
    color: #009DC0;
    font-weight: 100;
    padding: 20px 23px;
    margin: 0;
}
.AboutRowentaContent img {
	width: 500px;
}
.AboutRowentaContent a {
	color: #6d6e70;
	font-size: 18px;
}
.AboutRowentaInnovators,
.AboutRowentaSebgroup {
	width: 500px;
	margin-bottom: 35px;
    background: #EDEEF0;
    display: inline-block;
    padding-bottom: 40px;
}
.AboutRowentaInnovators {
	margin-right: 80px;
}
.AboutRowentaConentTxt {
	padding: 0 30px;
}
.AboutRowentaContentLead {
	font-size: 1.2rem;
    font-weight: 100;
    margin: 0 0 10px;
    line-height: 1.2em;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
	.AboutRowenta {
		margin: 0 15px;
		padding: 15px;
	}
	.AboutRowenta h2 {
	    margin: 0 0 45px;
	}
	.AboutRowentaIntroLead {
	    font-size: 19px;
	    font-size: 1.2rem;
	}
	.AboutRowentaInnovators {
		margin: 0 auto 30px;
	}
	.AboutRowentaInnovators,
	.AboutRowentaSebgroup {
		width: 100%;
	}
	.AboutRowentaContent h3 {
		font-size: 1.3rem;
		padding: 20px 15px;
	}
	.AboutRowentaConentTxt {
	    padding: 0 15px;
	}
}


.TopBannerSimpleContent img{
    width: 100%;
}
.TopBannerSimpleContent p{
    padding-left: 20px;
    text-align: left;
}



.GuaranteeBlock{
    padding: 18px 20px;
    background: #EDEEF0;
    margin: 0 0 15px;
}

.GuaranteeBlock .item h2{
    font-weight: 100;
    margin: 0 0 12px;
    padding: 12px 0;
    border-bottom: 1px solid #DCDEE2;
    border-top: 1px solid #DCDEE2;
    color: #009DC0;    
    line-height: 24px;    
    font-size: 24px;
}

.GuaranteeBlock .item p{
    font-size: 14.5px;
}

.GuaranteeBlock .item ul{
    padding: 0;
    margin: 0 0 10px 25px;
}
.GuaranteeBlock .item ul li{
    margin-bottom: 5px;
    text-align: left;
}
.GuaranteeBlock a{
    text-decoration: underline;
    font-size: 14px;
}
.GuaranteeBlock .GuaranteeBottom, .GuaranteeBlock .GuaranteeBottom a{
    font-size: 12px;
}

@media only screen and (max-width: 600px) {

}


.Benefits {
	clear: both;
	padding-top: 40px;
}

.Benefit {
	width: 20%;
	display: inline-block;
	padding: 0 20px;
	line-height: 20px;
	vertical-align: text-top;
	text-align: center;
}

.BenefitCaption {
	margin-top: 20px;
	min-height: 200px;
}

.BenefitCaption ul {
	padding-left: 10px;
	list-style: black;
	text-align: left;
}

.Benefit p {
	max-width: 160px;
	line-height: 1.2em;
	color: #6d6e70;
	margin: 0 auto;
	font-size: 14px;
}

.Benefit p:first-child {
	text-transform: uppercase;
	font-weight: 100;
	font-size: 15px;
	color: #00ACCF;
	line-height: 1.2em;
	margin-bottom: 15px;
	min-height: 54px;
}

.Benefit-img-wrapper {
	min-height: 170px;
}

.Benefit-img-wrapper img {
	width: 140px;
}

.DetailsNav {
	margin-bottom: 30px;
	margin-top: 50px;
}

.DetailsNav li {
	display: inline-block;
	vertical-align: middle;
	border-bottom: 3px solid #dbdcdc;
}

.DetailsNav li a {
	color: #000;
	display: block;
	height: 100%;
	padding: 18px;
	line-height: 18px;
	text-transform: uppercase;
	font-size: 16px;
}

.DetailsNav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

.DetailsNav li a:active {
	border-bottom: 3px solid rgba(0, 157, 192, 0.8);
}

.DetailsSection-1 .disclaimer {
	color: #a4b0b7;
}

.DetailsSection-1-inner {
	padding: 90px 60px 75px;
	margin: 0 auto;
	background-color: #000;
	color: #fff;
	text-align: center;
}

.DetailsSection-1 h2 {
	margin: 0 0 20px;
	font-weight: 700;
	font-size: 44px;
	line-height: 1em;
	text-transform: uppercase;
}

.DetailsSection-1 h3 {
	margin: 0 auto;
	width: 85%;
	padding: 13px;
	font-size: 28px;
	font-weight: 100;
	font-style: italic;
}

.DetailsSection-1 hr {
	width: 75px;
	margin: 0 auto;
}

.DetailsSection-1 p {
	line-height: 1.1em;
	font-size: 16px;
	padding: 0 150px;
}

.DetailsSection-1 {
	margin: 50px 0;
}

.DetailsSection-2 {
	margin-top: 30px;
	margin-bottom: 50px;
}

.FixedPartTable .picture img {
	max-height: 120px;
	max-width: 100px;
}

.FixedPartTable .name {
	font-weight: 100;
	margin: 0 auto;
	padding: 10px 5px;
	font-size: 18px;
	line-height: 1.1em;
	width: 65%;
}

.FixedPartTable .name a {
	color: #00ACCF;
	text-transform: uppercase;
	height: 120p;
}

.FixedPartTable .picture>a {
	min-height: 110px;
	display: block;
}

.FixedPartTable table tr {
	vertical-align: top;
}

.FixedPartTable table tr th {
	width: 20%;
	height: 150px;
}

.ScrollableTable td {
	padding: 10px 60px;
	border-top: 1px solid #EDEEF0;
}

.ScrollableTable td.info {
	text-align: left;
	padding: 10px 62px 10px 0;
}

.DetailsSection-3 {
	padding: 60px 0;
	text-align: center;
	background: #a4b0b7;
}

.Doc {
	background: #FFFFFF;
	height: 175px;
	border-radius: 25px;
	border: 2px solid #A4B0B8;
	display: block;
	padding: 15px;
	width: 12%;
	margin: 0 auto;
}

.Doc .DocIcon {
	margin: 0 auto;
}

.Doc .DocTitle {
	text-transform: uppercase;
	color: #000;
	line-height: 1em;
	margin: 10px auto;
}

.Doc .DocTitle p {
	margin: 10px auto;
}

.Doc hr {
	margin: 0 auto;
	color: #EDEEF0;
	width: 60%;
}

.DetailsSection-4 {
	padding-top: 30px;
}

.ProductBlock {
	margin-top: 10px;
	overflow: hidden;
	margin-bottom: 50px;
}

.ProductBlock-left {
	width: 50%;
	float: left;
}

.ProductBlock-left-main {
	border: solid 1px #dfe1e2;
	padding: 50px;
}

.ProductBlock-left-thumbnail {
	width: 25%;
	margin-top: 25px;
}

.ProductBlock-right {
	width: 50%;
	float: left;
	text-align: left;
	padding: 0 70px 25px 66px;
	margin: 0;
	color: #6d6e70;
	font-weight: 100;
	line-height: 24px;
	font-size: 16px;
}

.ProductBlock-right h2 {
	color: #1E252B;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1em;
	font-size: 45px;
	margin: 0 0 9px;
	padding: 0 0 12px;
	letter-spacing: -0.009em;
	position: relative;
}

.ProductBlock-right h3 {
	font-size: 25px;
	line-height: 22px;
	font-weight: 100;
}

.ProductBlock-right a {
	display: inline-block;
	margin-right: 10px;
	margin-top: 50px;
}

.ProductBlock-right p {
	color: #6d6e70;
}

.ProductBlock-right p.ref {
	border-top: solid 1px #dfe1e2;
	border-bottom: solid 1px #dfe1e2;
	width: 30%;
	padding: 10px 0;
}

.ProductBlock-left-main .product-image.hide {
	display: none;
}

.ProductBlock-left-thumbnail {
	width: 100%;
	box-sizing: border-box;
}

.ProductBlock-left-thumbnail img {
	display: inline-block;
	width: 25%;
	padding: 15px;
	box-sizing: border-box;
	border: solid 1px #dfe1e2;
}

.ProductBlock-left-thumbnail img:hover {
	cursor: pointer;
}

@media (max-width: 768px) {
	.Benefits {
		padding-top: 15px;
	}
	.ProductBlock-right, .ProductBlock-left {
		width: 100%;
	}
	.Product {
		width: 100% !important;
		padding: 0 15px;
	}
	.ProductBlock-right {
		padding: 0;
		margin-top: 15px;
	}
	.Product .btn.btn-primary {
		width: 100%;
		margin: 10px 0;
	}
	.Benefit {
		width: 100%;
		margin-bottom: 50px;
	}
	.Benefit-img-wrapper {
		min-height: 0;
	}
	.Benefit p:first-child {
		margin-bottom: 0;
	}
	.BenefitCaption {
		min-height: 0;
	}
	.DetailsSection-1 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	.DetailsSection-1-inner {
		padding: 15px;
	}
	.DetailsSection-1-inner h2 {
		font-size: 35px;
	}
	.DetailsSection-1-inner h3 {
		width: 100%;
		padding: 13px 0;
		font-size: 26px;
	}
	.DetailsSection-1 p {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.DetailsSection-3 {
		background: none;
		padding: 0;
	}
	.Doc {
		width: 100%;
	}
}

.ProductBlock {
	margin-bottom: 0;
}

.ProductBlock-left {
	width: 50%;
	float: left;
}

.ProductBlock-left-main {
	border: solid 1px #dfe1e2;
	padding: 50px;
}

.ProductBlock-left-thumbnail {
	width: 25%;
	margin-top: 25px;
}

.ProductBlock-right {
	width: 50%;
	float: left;
	text-align: left;
	padding: 0 70px 25px 66px;
	margin: 0;
	color: #6d6e70;
	font-size: 14.5px;
	line-height: 1.2em;
}

.ProductBlock-right h2 {
	color: #1E252B;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1em;
	font-size: 45px;
	margin: 10px 0 9px;
	padding: 0 0 12px;
	letter-spacing: -0.009em;
	position: relative;
}

.ProductBlock-right h3 {
	font-size: 25px;
	line-height: 22px;
	font-weight: 100;
}

.ProductBlock-right a {
	display: inline-block;
	margin-right: 10px;
	margin-top: 50px;
}

.ProductBlock-right p {
	color: #6d6e70;
}

.ProductBlock-right p.ref {
	border-top: solid 1px #dfe1e2;
	border-bottom: solid 1px #dfe1e2;
	width: 50%;
	padding: 10px 0;
}

.ProductBlock-left-main .product-image.hide {
	display: none;
}

.ProductBlock-left-thumbnail {
	width: 100%;
	box-sizing: border-box;
}

.ProductBlock-left-thumbnail img {
	display: inline-block;
	width: 25%;
	padding: 15px;
	box-sizing: border-box;
	border: solid 1px #dfe1e2;
}

.ProductBlock-left-thumbnail img:hover {
	cursor: pointer;
}

@media (max-width: 768px) {
	.ProductBlock-right, .ProductBlock-left {
		width: 100%;
	}
	.Product {
		width: 100% !important;
		padding: 0 15px;
	}
	.ProductBlock-right {
		padding: 0;
		margin-top: 15px;
	}
	.Product .btn.btn-primary {
		width: 100%;
		margin: 10px 0;
	}
	.Benefit {
		width: 100%;
		margin-bottom: 50px;
	}
	.Benefit-img-wrapper {
		min-height: 0;
	}
	.Benefit p:first-child {
		margin-bottom: 0;
	}
	.BenefitCaption {
		min-height: 0;
	}
	.DetailsSection-1 p {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.DetailsSection-3 {
		background: none;
		padding: 0;
	}
	.Doc {
		width: 100%;
	}
	.FixedPartTable table tr th {
		display: none;
	}
	.FixedPartTable table tr th:first-child {
		display: block;
	}
}

.ProductBoxesThree {
	padding-top: 35px;
	padding-bottom: 80px;
}

.RowItem {
	width: 25%;
	display: inline-block;
	border: 1px solid #e3e6e8;
	min-height: 370px;
	position: relative;
}

.RowItem_Img {
	height: 200px;
	padding: 10px 0;
}

.RowItem_Caption {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 220px;
	width: 100%;
	padding: 0 25px 20px 25px;
}

.RowItem_Title {
	font-size: 21px;
	margin: 0 0 4px;
	padding: 0 0 10px;
	color: #009DC0;
	line-height: 1em;
	text-transform: uppercase;
	position: relative;
	min-height: 52px;
}

.RowItem_Title:after {
	content: "";
	width: 25px;
	position: absolute;
	bottom: 0;
	display: block;
	left: 50%;
	margin-left: -12px;
	background: #A4B0B8;
	height: 1px;
}

.RowrItem_Description {
	font-size: 16px;
	color: #6d6e70;
	margin: 0 auto;
	min-height: 0;
	line-height: 1.2em;
	letter-spacing: -0.009em;
	width: 80%;
}

@media (max-width: 768px) {
	.RowItem {
		width: 100%;
	}
}
.whatis-row {
	margin: 10px 0;
}
.whatis-row-inner {
	display: flex;
}

.whatis-row-inner > div {
	flex: 1 1;
}

.whatis-row-info {
	padding: 22px 80px 22px 0px;
}

.whatis-row-info img {
	margin-left: 100px;
}

.whatis-row-info p {
	font-size: 1.1em;
	width: 75%;
}

.repair .section-title {
	color: #fff;
}

.onYourSide-row {
	padding: 40px 80px;
	background: #bcc5cc;
}

.onYourSide-row p {
	font-size: 1em;
	font-style: italic;
	color: #fff;
}

.onYourSide-row .title {
	font-family: 'PT sans-serif';
}

.onYourSide-row .top-content {
	display: flex;
}

.onYourSide-row .top-content > p {
	flex: 0.80 1;
	margin: 15px 30px;

}

.onYourSide-row .top-content > img {
	flex: 0.13 1;
}

.onYourSide-row .top-content > img:first-child {
	flex: 0.17 1;
}

.onYourSide-row hr {
	width: 25%;
	margin: 25px auto;
}

.onYourSide-row hr.hr {
	margin: 0 auto 40px;;
}

.onYourSide-row .bottom-content h2 {
	text-align: center;
	color: #fff;
}

.few-numbers {
	text-align: left;
	display: flex;
}

.few-numbers img {
	align-self: center;
	margin-right: 10px;
}

.few-numbers > .fex-numbers-right {
	margin-left: 30px;
}

.nearYou-row {
	display: flex;
	padding: 30px 0;
}

.nearYou-row > *{
	flex: 1 1;
}

.nearYou-row-info .section-title {
	color: #00b5d5;
}

.nearYou-row-info .section-title .top-title:before,
.nearYou-row-info .section-title .top-title:after {
	background: #00b5d5;
}

.nearYou-row-info .btn {
	margin-right: 15px;
}

.nearYou-row-location {
	background: url('https://www.rowenta.fr/medias/pinpoint.png?context=bWFzdGVyfHJvb3R8MTMxMDB8aW1hZ2UvcG5nfGg5Yi9oM2EvOTQwMzYxMTE4NTE4Mi5wbmd8MDI1ZjI1MmMxMTkyMjVkY2U4N2VlNjFjZWZlNjQ3MDgzYTdkZDQ2MDFiZjQyMDEyNDNkNjc4NzE2MDRiNDhhMg') no-repeat;
	background-size: contain;
	background-position: top center;
}

.nearYou-row-location p {
	position: relative;
	top: 38px;
	left: 0;
	color: white;
	font-size: 1.5em;
	text-align: center;
}

.nearYou-row-location p span {
	font-size: 4em;
}

.last-row {
	display: flex;
	margin-bottom: 25px;
}

.last-row h2 {
	text-align: center;
}

.last-row .img {
	margin: 0 auto;
	display: block;
	margin-bottom: 25px;
}

.oneClick,
._3d {
	background: #d6dce0;
	flex: 1 1;
	padding: 25px;
	border-radius: 5px;
}

.oneClick {
	margin-right: 10px;
}

._3d {
	margin-left: 10px;
}

.testimonial {
	display: flex;
}

.testimonial img {
	margin-bottom: 5px;
	margin-right: 15px;
	align-self: center;
}

.iframe-container-bot {
	width: 450px;
	margin: 0 auto;
	height: 250px;
}

.iframe-container-bot iframe {
	width: 100%;
	height: 100%;
}

@media (max-width: 768px) {
	.whatis-row-inner {
		flex-direction: column;
	}

	.whatis-row-video iframe {
		width: 280px;
		height: 160px;
		margin: 0 auto;
  	display: block;
	}

	.whatis-row-info {
		text-align: center;
		padding: 0;
	}

	.whatis-row-info img {
		margin: 0 auto;
	}

	.whatis-row-info p {
		margin: 0 auto;
	}

	.whatis-row-video {
		margin: 25px auto ;
	}

	.onYourSide-row {
		padding: 10px 25px;
		background: #bcc5cc;
	}

	.onYourSide-row .section-title .bottom-title {
		font-size: 42px;
	}

	.onYourSide-row .top-content {
		flex-direction: column;
	}

	.onYourSide-row .top-content img {
		flex: 1 1;
		width: 100px;
		margin: 10px auto;
	}

	.nearYou-row {
		flex-direction: column;
		padding: 30px 15px;
	}

	.nearYou-row-location {
		margin-bottom: 15px;
	}

	.nearYou-row-location p {
		color: black;
	}

	.last-row {
		flex-direction: column;
	}

	.last-row .lead {
		text-align: center;
	}

	.last-row iframe {
		width: 280px;
		height: 160px;
		display: block;
		margin: 0 auto;
	}

	.oneClick,
	._3d {
		margin: 10px 0;
		border-radius: 0;
	}

	.testimonial {
		flex-direction: column;
	}

	.iframe-container-bot {
		padding: 0;
		width: auto;
		height: auto;
	}
}

.repair-centre-section {
	background: #e6e9ea;
	padding: 15px;
	margin: 25px 0;
}

.repair-centre-section h3 {
	margin: 10px 0;
}

.repair-centre {
	padding: 25px;
  border-bottom: 2px solid #797e82;
}

.repair-centre strong {
	font-size: 20px;
}
.CookiesRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.CookiesRowenta a,
.CookiesRowenta a:hover,
.CookiesRowenta a:focus,
.CookiesRowenta a:active {
	color: #029dc0;
}

.CookiesRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.CookiesRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.CookiesRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
	margin-top: 40px;
}

.CookiesRowenta ol li,
.CookiesRowenta ul li {
	margin-bottom: 10px;
	padding-left: 10px;
}

.CookiesRowenta ul {
	padding-left: 15px;
	list-style-type: disc;
}
.TermsRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.TermsRowenta a,
.TermsRowenta a:hover,
.TermsRowenta a:focus,
.TermsRowenta a:active {
	color: #029dc0;
}

.TermsRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.TermsRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.TermsRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
}

.TermsRowenta ol li {
	margin-bottom: 20px;
	padding-left: 10px;
}
.PrivacyRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.PrivacyRowenta a,
.PrivacyRowenta a:hover,
.PrivacyRowenta a:focus,
.PrivacyRowenta a:active {
	color: #029dc0;
}

.PrivacyRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.PrivacyRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.PrivacyRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
	margin-top: 40px;
}

.PrivacyRowenta ol li,
.PrivacyRowenta ul li {
	margin-bottom: 10px;
	padding-left: 10px;
}

.PrivacyRowenta ul {
	padding-left: 15px;
	list-style-type: disc;
}
.ContactUsBanner {
	background: url(/static/media/about-us-bg.9edacdbf.jpg);
	text-align: center;
    padding: 44px 0;
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
}
.ContactUsBanner h1 {
    line-height: 1.5em;
    font-size: 45px;
    font-size: 3.0rem;
    color: #6d6e70;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Uni Sans', sans-serif;
    font-weight: bold;
}

.iframe-wrapper{
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    height: 1080px;

    overflow: auto;    
}
.Where-to-buy .main-content img{
	width: 80%;
	display: block;
	margin: 25px auto;
}

.Where-to-buy .main-content .WtbText {
	margin: 0 15px;
}

.Where-to-buy .main-content .WtbProds {
	margin: 15px 15px 0 15px;
}


.Where-to-buy .main-content hr{
	opacity: 0.2;
}

.Where-to-buy h2 {
	font-size: 1.2em;
}

.Where-to-buy .store-link {
	text-align: center;
}

.Where-to-buy .store-link a {
	display: inline-block;
	width: 18%;
	text-align: center;
	background-color: #f6f6f6;
	border: 1px solid #d8d7d7;
	border-radius: 16px 16px 0;
	box-sizing: border-box;
	text-decoration: none;
}

.Where-to-buy .store-link a img {
	vertical-align: bottom;
	margin: 5px 0;
}

.Where-to-buy .store-link a p {
	margin: 0;
	color: black;
}

.Where-to-buy .main-content .btn.shopnow {
	font-size: 20px;
	border-radius: 5px;
	padding: 12px 75px;
	margin: 0 0 40px;
	display: inline-block;
}

.Where-to-buy .retailers-row{
	display: flex;
	justify-content: space-between;
}
.Where-to-buy .retailers-row .retailer{
	flex: 0 1 30%;
	text-align: center;
}

.Where-to-buy .retailers-row .retailer img{
	max-width: 217px;
}

.retailer {
	border: 2px solid #e6e6e6;
  border-radius: 3px;
}
.retailer:hover {
	border-color: rgba(2, 157, 192, 0.3);
}
/* Desktop */

@media screen and (min-width: 620px) {
	.Where-to-buy .main-content {
		min-height: 450px;
	}
	.Where-to-buy .main-content .WtbText {
		margin: 0;
	}
	.Where-to-buy .main-content {
		margin-bottom: 50px;
		margin-top: -16px;
	}
	.Where-to-buy .main-content hr{
		margin-bottom: 45px;
		margin-top: 40px;
		opacity: 0.2;
	}
	.Where-to-buy h2 {
		margin-top: 50px;
		font-size: 1.5em;
	}
	.Where-to-buy .main-content .btn.shopnow {
		margin: 50px 0;
	}

}

@media screen and (max-width: 768px) {
	.retailers-row {
		flex-direction: column;
		padding: 10px;
	}

	.Where-to-buy .main-content img {
		width: 100%;
	}
}
@font-face {
	font-family: 'Uni Sans';
	font-style: normal;
	font-weight: 400;
	src: url(/static/media/Unisans.196f5b40.eot);
	src: url(/static/media/Unisans.196f5b40.eot?#iefix) format('embedded-opentype'), url(/static/media/Unisans.76efa2ca.woff2) format('woff2'), url(/static/media/Unisans.7812b3a7.woff) format('woff'), url(/static/media/Unisans.21803d74.ttf) format('truetype'), url(/static/media/Unisans.41094c8c.svg#UniSans) format('svg');
}

@font-face {
	font-family: 'PT italics';
	font-style: normal;
	font-weight: 400;
	src: url(/static/media/PT_Italic.0cff059f.eot);
	src: url(/static/media/PT_Italic.0cff059f.eot?#iefix) format('embedded-opentype'), url(/static/media/PT_Italic.bdfcebc8.woff2) format('woff2'), url(/static/media/PT_Italic.8b62c1f5.woff) format('woff'), url(/static/media/PT_Italic.159feaba.ttf) format('truetype'), url(/static/media/PT_Italic.9bf92c16.svg#PT_Italic) format('svg');
}

body {
	font-family: 'Uni Sans', sans-serif;
	margin: 0;
}

i {
	font-family: 'PT italics', sans-serif;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
	padding-left: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.btn {
	min-width: 140px;
	padding: 9px 25px;
	border: 0;
	border-radius: 35px;
	text-align: center;
	font-size: 12px;
	text-shadow: none;
	transition: all 0.3s;
	background: #009DC0;
	color: #fff;
	font-family: 'PT sans', sans-serif;
}

.btn-big {
	min-width: 200px;
	font-size: 16px;
}

.btn.btn-primary {
	background: #009DC0;
	color: #fff;
}

.SectionTitleSimple {
	margin: 0 auto;
	padding: 13px;
	font-size: 35px;
	font-weight: 100;
	color: #a4b0b7;
}

.btn-small {
	border-radius: 15px;
  background: #20a9ca;
  padding: 6px 18px;
  color: #fff;
  display: inline-block;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

.frame::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
} 


.content {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	margin-top: 25px;
}

.link {
	text-decoration: underline;
}

.store {
	display: flex;
}

.stores {
	margin-left: 25px;
}

.store img {
	align-self: center;
	margin-right: 10px;
}

@media (max-width: 768px) {
	.content {
		flex-direction: column;
		padding: 0;
		align-items: flex-start;
	}

	.stores {
		margin: 15px 0;
	}

	.page {
		padding: 0 15px;
	}
}
