.whatis-row {
	margin: 10px 0;
}
.whatis-row-inner {
	display: flex;
}

.whatis-row-inner > div {
	flex: 1;
}

.whatis-row-info {
	padding: 22px 80px 22px 0px;
}

.whatis-row-info img {
	margin-left: 100px;
}

.whatis-row-info p {
	font-size: 1.1em;
	width: 75%;
}

.repair .section-title {
	color: #fff;
}

.onYourSide-row {
	padding: 40px 80px;
	background: #bcc5cc;
}

.onYourSide-row p {
	font-size: 1em;
	font-style: italic;
	color: #fff;
}

.onYourSide-row .title {
	font-family: 'PT sans-serif';
}

.onYourSide-row .top-content {
	display: flex;
}

.onYourSide-row .top-content > p {
	flex: 0.80;
	margin: 15px 30px;

}

.onYourSide-row .top-content > img {
	flex: 0.13;
}

.onYourSide-row .top-content > img:first-child {
	flex: 0.17;
}

.onYourSide-row hr {
	width: 25%;
	margin: 25px auto;
}

.onYourSide-row hr.hr {
	margin: 0 auto 40px;;
}

.onYourSide-row .bottom-content h2 {
	text-align: center;
	color: #fff;
}

.few-numbers {
	text-align: left;
	display: flex;
}

.few-numbers img {
	align-self: center;
	margin-right: 10px;
}

.few-numbers > .fex-numbers-right {
	margin-left: 30px;
}

.nearYou-row {
	display: flex;
	padding: 30px 0;
}

.nearYou-row > *{
	flex: 1;
}

.nearYou-row-info .section-title {
	color: #00b5d5;
}

.nearYou-row-info .section-title .top-title:before,
.nearYou-row-info .section-title .top-title:after {
	background: #00b5d5;
}

.nearYou-row-info .btn {
	margin-right: 15px;
}

.nearYou-row-location {
	background: url('https://www.rowenta.fr/medias/pinpoint.png?context=bWFzdGVyfHJvb3R8MTMxMDB8aW1hZ2UvcG5nfGg5Yi9oM2EvOTQwMzYxMTE4NTE4Mi5wbmd8MDI1ZjI1MmMxMTkyMjVkY2U4N2VlNjFjZWZlNjQ3MDgzYTdkZDQ2MDFiZjQyMDEyNDNkNjc4NzE2MDRiNDhhMg') no-repeat;
	background-size: contain;
	background-position: top center;
}

.nearYou-row-location p {
	position: relative;
	top: 38px;
	left: 0;
	color: white;
	font-size: 1.5em;
	text-align: center;
}

.nearYou-row-location p span {
	font-size: 4em;
}

.last-row {
	display: flex;
	margin-bottom: 25px;
}

.last-row h2 {
	text-align: center;
}

.last-row .img {
	margin: 0 auto;
	display: block;
	margin-bottom: 25px;
}

.oneClick,
._3d {
	background: #d6dce0;
	flex: 1;
	padding: 25px;
	border-radius: 5px;
}

.oneClick {
	margin-right: 10px;
}

._3d {
	margin-left: 10px;
}

.testimonial {
	display: flex;
}

.testimonial img {
	margin-bottom: 5px;
	margin-right: 15px;
	align-self: center;
}

.iframe-container-bot {
	width: 450px;
	margin: 0 auto;
	height: 250px;
}

.iframe-container-bot iframe {
	width: 100%;
	height: 100%;
}

@media (max-width: 768px) {
	.whatis-row-inner {
		flex-direction: column;
	}

	.whatis-row-video iframe {
		width: 280px;
		height: 160px;
		margin: 0 auto;
  	display: block;
	}

	.whatis-row-info {
		text-align: center;
		padding: 0;
	}

	.whatis-row-info img {
		margin: 0 auto;
	}

	.whatis-row-info p {
		margin: 0 auto;
	}

	.whatis-row-video {
		margin: 25px auto ;
	}

	.onYourSide-row {
		padding: 10px 25px;
		background: #bcc5cc;
	}

	.onYourSide-row .section-title .bottom-title {
		font-size: 42px;
	}

	.onYourSide-row .top-content {
		flex-direction: column;
	}

	.onYourSide-row .top-content img {
		flex: 1;
		width: 100px;
		margin: 10px auto;
	}

	.nearYou-row {
		flex-direction: column;
		padding: 30px 15px;
	}

	.nearYou-row-location {
		margin-bottom: 15px;
	}

	.nearYou-row-location p {
		color: black;
	}

	.last-row {
		flex-direction: column;
	}

	.last-row .lead {
		text-align: center;
	}

	.last-row iframe {
		width: 280px;
		height: 160px;
		display: block;
		margin: 0 auto;
	}

	.oneClick,
	._3d {
		margin: 10px 0;
		border-radius: 0;
	}

	.testimonial {
		flex-direction: column;
	}

	.iframe-container-bot {
		padding: 0;
		width: auto;
		height: auto;
	}
}

.repair-centre-section {
	background: #e6e9ea;
	padding: 15px;
	margin: 25px 0;
}

.repair-centre-section h3 {
	margin: 10px 0;
}

.repair-centre {
	padding: 25px;
  border-bottom: 2px solid #797e82;
}

.repair-centre strong {
	font-size: 20px;
}