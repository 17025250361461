.TopBanner {
	background-size: cover;
	background-position: center;
	color: #000;
	display: flex;
  justify-content: center;
	align-items: center;
	padding: 25px 0;
}

.TopBanner h1 {
	font-size: 45px;
	position: relative;
	font-family: 'PT sans', sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.1em;
	text-align: center;
	margin-bottom: 15px;
	margin-top: 0;
}

.TopBannerContent {
	text-align: center;
}

.TopBannerDesc {
	font-family: 'PT italics', sans-serif;
  font-size: 26px;
  text-align: center;
  width: 250px;
	margin: 0 auto;
	margin-bottom: 25px;
}

@media (min-width: 768px) {
	.TopBanner {
		min-height: 450px;
	}
}