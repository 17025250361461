.ConsumerServices {
	clear: both;
	background: #1E252B;
	font-family: 'PT Sans', sans-serif;
	padding-bottom: 50px;
	padding-top: 30px;
}

.Services {
	display: flex;
	justify-content: space-between;
}

.ServiceTitle {
	font-size: 16px;
	font-weight: 100;
}

.Service {
	text-align: center;
	color: #fff;
	max-width: 175px;
	margin: 25px auto;
}

.ServiceDescription {
	color: #B1BDC5;
	font-size: 12px;
}

.ServiceHr {
	margin: 10px 50px;
  border-color: #868686;
}

@media (max-width: 768px) {
	.ConsumerServices{
		padding-bottom: 0;
	}

	.ConsumerServices .section-title{
		margin-bottom: 0;
	}

	.Services {
		flex-direction: column;		
	}

	.Services .Service{
		margin: 15px auto;
	}
}