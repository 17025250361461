.PrivacyRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.PrivacyRowenta a,
.PrivacyRowenta a:hover,
.PrivacyRowenta a:focus,
.PrivacyRowenta a:active {
	color: #029dc0;
}

.PrivacyRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.PrivacyRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.PrivacyRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
	margin-top: 40px;
}

.PrivacyRowenta ol li,
.PrivacyRowenta ul li {
	margin-bottom: 10px;
	padding-left: 10px;
}

.PrivacyRowenta ul {
	padding-left: 15px;
	list-style-type: disc;
}