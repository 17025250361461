.SubNav {
	margin-top: 0;
	position: relative;
	z-index: 20;
	background-color: rgba(0, 157, 192, 0.8);
	color: #fff;
}

.SubNav ul {
	display: block;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	font-size: 0;
	background-color: #00A7CA;
	background-color: rgba(0, 167, 202, 0.8);
}

.SubNav nav li {
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	height: 50px;
}

.SubNav nav li:hover {
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.9);
}

.SubNav nav li a {
	display: block;
	height: 100%;
	padding: 0 18px;
	line-height: 50px;
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
}

.SubNav nav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

@media screen and (min-width: 440px) {
	.Subnav {
		margin-top: -70px;
	}
}

@media screen and ( min-width: 768px) {
	.SubNav ul {
		text-align: left;
		min-height: 70px;
		display: flex;
		justify-content: space-between;
	}
	.SubNav nav li {
		border-bottom: none;
		height: 70px;
		margin: 0% 3%;
	}
	.SubNav nav li a {
		line-height: 70px;
	}
}