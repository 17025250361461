.burger-icon {
    position: absolute;
    bottom: 20px;
    width: 30px;
    height: 4px;
    transition: .5s;
    background: #fff;
    float: left;
    z-index: 1;
    margin: 0;
}

.burger-icon:before {
    top: 7px;
}

.burger-icon:after {
    top: -7px;
}

.burger-icon:before, .burger-icon:after {
    display: block;
    content: "";
    width: 30px;
    height: 4px;
    position: absolute;
    z-index: -1;
    transition: .5s .25s;
    background: #fff;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}