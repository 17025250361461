@font-face {
	font-family: 'Uni Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/Unisans.eot');
	src: url('../fonts/Unisans.eot?#iefix') format('embedded-opentype'), url('../fonts/Unisans.woff2') format('woff2'), url('../fonts/Unisans.woff') format('woff'), url('../fonts/Unisans.ttf') format('truetype'), url('../fonts/Unisans.svg#UniSans') format('svg');
}

@font-face {
	font-family: 'PT italics';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/PT_Italic.eot');
	src: url('../fonts/PT_Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/PT_Italic.woff2') format('woff2'), url('../fonts/PT_Italic.woff') format('woff'), url('../fonts/PT_Italic.ttf') format('truetype'), url('../fonts/PT_Italic.svg#PT_Italic') format('svg');
}

body {
	font-family: 'Uni Sans', sans-serif;
	margin: 0;
}

i {
	font-family: 'PT italics', sans-serif;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
	padding-left: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.btn {
	min-width: 140px;
	padding: 9px 25px;
	border: 0;
	border-radius: 35px;
	text-align: center;
	font-size: 12px;
	text-shadow: none;
	transition: all 0.3s;
	background: #009DC0;
	color: #fff;
	font-family: 'PT sans', sans-serif;
}

.btn-big {
	min-width: 200px;
	font-size: 16px;
}

.btn.btn-primary {
	background: #009DC0;
	color: #fff;
}

.SectionTitleSimple {
	margin: 0 auto;
	padding: 13px;
	font-size: 35px;
	font-weight: 100;
	color: #a4b0b7;
}

.btn-small {
	border-radius: 15px;
  background: #20a9ca;
  padding: 6px 18px;
  color: #fff;
  display: inline-block;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

.frame::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
} 