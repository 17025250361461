.Where-to-buy .main-content img{
	width: 80%;
	display: block;
	margin: 25px auto;
}

.Where-to-buy .main-content .WtbText {
	margin: 0 15px;
}

.Where-to-buy .main-content .WtbProds {
	margin: 15px 15px 0 15px;
}


.Where-to-buy .main-content hr{
	opacity: 0.2;
}

.Where-to-buy h2 {
	font-size: 1.2em;
}

.Where-to-buy .store-link {
	text-align: center;
}

.Where-to-buy .store-link a {
	display: inline-block;
	width: 18%;
	text-align: center;
	background-color: #f6f6f6;
	border: 1px solid #d8d7d7;
	border-radius: 16px 16px 0;
	box-sizing: border-box;
	text-decoration: none;
}

.Where-to-buy .store-link a img {
	vertical-align: bottom;
	margin: 5px 0;
}

.Where-to-buy .store-link a p {
	margin: 0;
	color: black;
}

.Where-to-buy .main-content .btn.shopnow {
	font-size: 20px;
	border-radius: 5px;
	padding: 12px 75px;
	margin: 0 0 40px;
	display: inline-block;
}

.Where-to-buy .retailers-row{
	display: flex;
	justify-content: space-between;
}
.Where-to-buy .retailers-row .retailer{
	flex: 0 1 30%;
	text-align: center;
}

.Where-to-buy .retailers-row .retailer img{
	max-width: 217px;
}

.retailer {
	border: 2px solid #e6e6e6;
  border-radius: 3px;
}
.retailer:hover {
	border-color: rgba(2, 157, 192, 0.3);
}
/* Desktop */

@media screen and (min-width: 620px) {
	.Where-to-buy .main-content {
		min-height: 450px;
	}
	.Where-to-buy .main-content .WtbText {
		margin: 0;
	}
	.Where-to-buy .main-content {
		margin-bottom: 50px;
		margin-top: -16px;
	}
	.Where-to-buy .main-content hr{
		margin-bottom: 45px;
		margin-top: 40px;
		opacity: 0.2;
	}
	.Where-to-buy h2 {
		margin-top: 50px;
		font-size: 1.5em;
	}
	.Where-to-buy .main-content .btn.shopnow {
		margin: 50px 0;
	}

}

@media screen and (max-width: 768px) {
	.retailers-row {
		flex-direction: column;
		padding: 10px;
	}

	.Where-to-buy .main-content img {
		width: 100%;
	}
}