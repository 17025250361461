.FeaturedProducts {
	padding-top: 35px;
	padding-bottom: 50px;
}

.Products {
	margin: 25px 0;
	display: block;
	overflow: hidden;
}

@media (max-width: 768px) {
	.products {
		flex-direction: column;
	}
}