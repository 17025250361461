

.content {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	margin-top: 25px;
}

.link {
	text-decoration: underline;
}

.store {
	display: flex;
}

.stores {
	margin-left: 25px;
}

.store img {
	align-self: center;
	margin-right: 10px;
}

@media (max-width: 768px) {
	.content {
		flex-direction: column;
		padding: 0;
		align-items: flex-start;
	}

	.stores {
		margin: 15px 0;
	}

	.page {
		padding: 0 15px;
	}
}