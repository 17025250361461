.Hero {
	clear: both;
	max-height: 500px;
	overflow: hidden;
}

.HeroInner {
	background: linear-gradient(
		rgba(0, 0, 0, 0.25),
		rgba(0, 0, 0, 0.25)
	), url(../images/femme.jpg) no-repeat;
	background-size: cover;
	height: 200px;
	position: relative;
	width: 100%;
	margin: 0 auto;
	pointer-events: none;
	animation: videoout 2.5s linear forwards;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	background: black;
	z-index: -1;
}

@keyframes videoout {
  from {
		background: linear-gradient(
			rgba(0, 0, 0, 0.25),
			rgba(0, 0, 0, 0.25)
		), url(../images/femme.jpg) no-repeat;
	}
  to {
		background: rgba(0, 0, 0, 0.25);
	}
}

.video-wrapper iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.8;
}

.Hero .h1 {
  position: absolute;
  top: 50%;
  z-index: 1;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px #44bdd8;
  text-align: center;
  line-height: 1em;
  font-weight: 300;
  color: #fff;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 100%;
}

.Hero h2 {
	position: absolute;
	text-shadow: 1px 1px 3px #44bdd8;
  bottom: 25px;
	margin: auto;
	z-index: 1;
	display: none;
	font-weight: 300;
	font-family: 'Merriweather', serif;
	text-align: center;
	font-size: 30px;
	margin: 0 auto;
  width: 100%;
}

.videoWrapper {
	display: NONE;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 460px) {
	.Hero .h1 {
		font-size: 30px;
	}
}

@media screen and (min-width: 768px) {
	.HeroInner {
		height: 300px;
	}
}

@media screen and ( min-width: 992px) {
	.HeroInner {
		height: 500px;
	}
	.Hero .h1 {
		font-size: 60px;
	}
	.Hero h2 {
		display: block;
		color: #fff;
	}
}