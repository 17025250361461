.section-title {
  line-height: 1.5em;
  position: relative;
  font-weight: 100;
  color: gray;
  margin: 0 0 25px;
  font-size: 35px;
  display: block;
  width: auto;
  text-align: center;
  clear: both;
}

.section-title .top-title {
  font-size: 40px;
  padding: 5px 10px;
  position: relative;
}

.section-title .top-title:before {
  position: absolute;
  content: "";
  height: 1px;
  background: #bfbfbf;
  top: 50%;
  left: -20px;
  width: 25px;
}

.section-title .top-title:after {
  position: absolute;
  content: "";
  height: 1px;
  background: #bfbfbf;
  top: 50%;
  right: -20px;
  width: 25px;
}

.section-title .bottom-title {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
	width: 100%;
	font-family: 'PT sans';
}